<template>
  <!-- Start Our History Area -->
  <section>
    <div class="container bg-our-history">
      <!-- <div class="section-title">
        <h2 class="text-center">{{ $t("our-history.title") }}</h2>
        <div class="bar"></div>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="funfact">
            <h3>
              <span class="odometer">
                <IOdometer
                  class="odometer"
                  :value="clients"
                  :duration="200"
                /> </span
              >+
            </h3>
          </div>
          <p class="text-center">{{ $t("our-history.clients") }}</p>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="funfact">
            <h3>
              <span class="odometer">
                <IOdometer
                  class="odometer"
                  :value="transactions"
                  :duration="200"
                /> </span
              >+
            </h3>
          </div>
          <p class="text-center">{{ $t("our-history.transactions") }}</p>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="funfact">
            <h3>
              <span class="odometer">
                <IOdometer class="odometer" :value="years" /> </span
              >+
            </h3>
          </div>
          <p class="text-center">{{ $t("our-history.years") }}</p>
        </div>
      </div> -->

      <div class="contact-cta-box">
        <h3>{{ $t("contact-us.title") }}</h3>
        <p>{{ $t("contact-us.sub-title") }}</p>
        <a :href="whatsAppUrl" target="_blank" class="btn btn-primary">{{
          $t("contact-us.button")
        }}</a>
      </div>

      <!-- <div class="map-bg">
        <img src="../../../assets/img/map.png" alt="map" />
      </div> -->
    </div>
  </section>
  <!-- End Our History  Area -->
</template>

<script>
/* import IOdometer from "vue-odometer";
import "odometer/themes/odometer-theme-default.css"; */

export default {
  name: "History",
  data() {
    return {
      clients: 2000,
      transactions: 300000,
      years: 5,
      whatsAppUrl: `https://wa.link/62e5ko`,
    };
  },
  mounted() {
    const that = this;
    setInterval(function () {
      that.clients += 1;
    }, 5000);
    setInterval(function () {
      that.transactions += 1;
    }, 3000);
    /* setInterval(function() {
      that.years += 1;
    }, 2000); */
  },
  /* components: {
    IOdometer
  } */
};
</script>

<style scoped>
h2 {
  text-align: center;
}
.bg-our-history {
  margin-bottom: 60px;
}
</style>
